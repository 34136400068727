import { FormItem, FormLabel, TextField } from 'components'
import { ReactComponent as IconPaint } from 'assets/svg/paint.svg'
import styles from './ColorPicker.module.scss'

type ColorPickerProps = {
  onSelectValue: (color: string) => void
  value?: string
}

export const ColorPicker = ({ value, onSelectValue }: ColorPickerProps) => {
  return (
    <div className={styles.container}>
      <FormItem>
        <FormLabel>Cor</FormLabel>
        <TextField placeholder="#000000" value={value} />
      </FormItem>
      <div className={styles.inputColor}>
        <input
          type="color"
          onChange={(color) => onSelectValue(color.target.value)}
        />
        <IconPaint />
      </div>
    </div>
  )
}
